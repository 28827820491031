import React, { useEffect } from 'react';
import "../css/socialButton.scss";

export default function SocialButton(props) {
    useEffect(() => {
    },[]);
    return(
        <button className="social_button">
            {props.name}
        </button>
    )
}