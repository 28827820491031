import React, { useEffect, useState } from 'react';
import { gsap } from "gsap";
import "../css/talentCore.css";
import recDots from "../gifs/rec_dots.gif";
import midBar from "../gifs/mid_line.gif";
import sqDots from "../gifs/square_dots.gif";
import dBkBars from "../images/back_bars_diagonal.png";
import circleOut from "../gifs/big_circle_outlines.gif";
import bigCircle from "../images/big_circle.png";
import bBkBars from "../images/bottom_bar.png";
import SocialButton from '../component/socialButton';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TalentCore = ({details}) => {
   const [showKeyIllust, triggerIllust] = useState(true);
   const defaultRevealAnimation = (elem, direction) => {
      direction = direction || 1;
      let x = 0, y = direction * 100;
      if (elem.classList.contains("LReveal")) {
         x = -100;
         y = 0;
      } else if (elem.classList.contains("RReveal")) {
         x = 100;
         y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: x, y: y, opacity: 0.01 }, {
         duration: 1.5,
         x: 0,
         y: 0,
         opacity: 1,
         ease: "expo",
         overwrite: "auto",
         delay:1,
      });
   }
   const hide = (elem) => {
      gsap.set(elem, { opacity: 0.01 });
   }

   const changeIllust = () => {
      triggerIllust(!showKeyIllust);
   }
   



   useEffect(() => {
      // unmountAnimation();
      let tl = gsap.timeline();
      // animation chain for all the delayed animation component
      const talentAnimation = () => {
         tl.fromTo(".talent_reveal_trans", { autoAlpha: 0.01 }, { autoAlpha: 0.24, ease: "ease", overwrite: "auto", duration: 0.7 , delay:1 })
            .fromTo(".talent_bk_circle_out", { autoAlpha: 0.01 }, { autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 0.7 }, "-=0.6")
            .fromTo(".talent_reveal0", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.5")
            .fromTo(".talent_reveal1", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal2", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal3", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=1.2")
            .fromTo(".talent_reveal4", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal5", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8");
      }
      const mountAnimation = () => {
         // console.log("mounted talent")
         gsap.registerPlugin(ScrollTrigger);
         gsap.utils.toArray(".reveal").forEach(function (elem) {
            hide(elem);
            ScrollTrigger.create({
               trigger: ".talent-container",
               start: "top 60%",
               // markers:true,
               once: true,
               onEnter: function () { defaultRevealAnimation(elem) },
            });
         });
         // setting all the delayed animation component to be not visible
         gsap.set(".talent_reveal_trans", { autoAlpha: 0.01 });
         gsap.set(".talent_bk_circle_out", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal0", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal1", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal2", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal3", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal4", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal5", { autoAlpha: 0.01 });

         ScrollTrigger.create({
            trigger: ".talent-container",
            start: "top 60%",
            once: true,
            onEnter: talentAnimation,
         });

      }
      mountAnimation();
      // return () => { unmountAnimation(); }
   }, []);

   return (
      <div className="talent-wrapper">
         <img className="bk_bars_diagonal talent_reveal_trans" src={dBkBars} alt=""></img>
         <div className="talent-container">
            {/* <img className="talent_bk_top_bar" src={topBar}></img> */}
            <img className="talent_bk_rec_dots reveal" src={recDots} alt=""></img>
            <div className="talent_bk_tl_bar reveal LReveal">
               <div id="tl_square"></div>
               <hr id="tl_line"></hr>
            </div>

            <img className="talent_bk_circle_out" src={circleOut} alt=""></img>
            <img className="talent_bk_big_circle talent_reveal_trans" src={bigCircle} alt=""></img>
            <img className="talent_bk_bottom_bar talent_reveal_trans" src={bBkBars} alt=""></img>
            <div className="talent_slogan">
               <span className="talent_slogan_top talent_reveal4">{details.slogan_top}</span>
               <span className="talent_slogan_bot talent_reveal5">{details.slogan_bot}</span>
            </div>
            <div className="talent-inner-container">
               <div className="talent-info-box">
                  <img className="talent_numb reveal" src={details.number} alt=""></img>
                  <div className="talent_details ">
                     <img className="talent_logo talent_reveal0" src={details.logo} alt=""></img>
                     <div className="talent_des talent_reveal1">{details.description}</div>
                     <a href={details.youtube} target="_blank" className="talent_links talent_reveal2" rel="noreferrer"><SocialButton name="Youtube" aria-label="youtube link"/></a>
                     <a href={details.twitter} target="_blank" className="talent_links talent_reveal2" rel="noreferrer"><SocialButton name="Twitter" aria-label="twitter link"/></a>

                     <div className='talent_reveal2'>illustrator: <a href={details.illustrator_link} target="_blank" className="talent_links" rel="noreferrer">{details.illustrator}</a></div>
                     <img className="talent_bk_mid_bar reveal" src={midBar} alt=""></img>
                     <div className='talent_reveal2'>modeler: <a href={details.modeler_link} target="_blank" className="talent_links" rel="noreferrer">{details.modeler}</a></div>
                     <img className="talent_bk_mid_bar reveal" src={midBar} alt=""></img>
                  </div>

               </div>
               <div className="talent-img-box">
                  <div className="talent_img_container">
                     <p className="arrow_container talent_reveal4" onClick={changeIllust}><i className="arrow ar_left"></i></p>
                     <img className="talent-body talent_reveal3 key-illust" style={{display: showKeyIllust ? 'block' : 'none'}} src={details.keyIllust} alt="illust"></img>
                     <img className="talent-body talent_reveal3 base-illust" style={{display: showKeyIllust ? 'none' : 'block'}} src={details.baseIllust} alt="illust"></img>
                     <p className="arrow_container talent_reveal4" onClick={changeIllust}><i className="arrow ar_right"></i></p>
                  </div>

                  <img className="talent_bk_sq_dots reveal RReveal" src={sqDots} alt=""></img>

               </div>
            </div>
         </div>
      </div>
   )
}
export default TalentCore
