// import * as React from "react";
import React, { useEffect } from 'react';
// import { graphql, Link } from "gatsby"
// import "../../css/talent.css";
import Body from "../../images/ren/ren.png";
import Key from "../../images/ren/ren_key.png";
import number from "../../images/ren/03.png";
import logo from "../../images/ren/RenName.png";
import TalentCore from '../../component/talentCore';

const ren_details = {
   name: `ren`,
   description: `ダンスが大好きなアクティブな少女。
   不本意ながらも、アイドル活動を行うこととなった。
   どうやらPROJECT NEBULAの裏を知っているようだ…
   クールな風貌とは裏腹におちゃめなところもある。`,
   slogan_top: `「ここが自分の居場所でもいいのかな…」`,
   slogan_bot: `「最高の思い出、あたしと作ろうよ！」`,
   twitter: "https://twitter.com/Ren_Suzuri",
   youtube: "https://www.youtube.com/@RenSuzuri",
   hashtag: "#鈴莉れん",
   illustrator: "とき",
   illustrator_link: "https://twitter.com/toki_ship8",
   modeler: "Pankofox (aeru studio)",
   modeler_link: "https://twitter.com/pankofox",
   number: number,
   logo: logo,
   keyIllust: Key,
   baseIllust: Body
}

const Suzuri_Ren = () => {
   return (
      <TalentCore details={ren_details}/>
   )
}

export default Suzuri_Ren